// AudioContext.js
import React, { Component, createContext } from 'react';

const AudioContext = createContext();

class AudioContextProvider extends Component {
  state = {
    audioSource: '', // Initialize with your default audio source
    isPlaying: false,
  };

  componentDidMount() {
    // Load audio source from localStorage if available
    const audioSource = localStorage.getItem('audioSource');
    if (audioSource) {
      this.setState({ audioSource });
    }

    // Load audio playback state from localStorage if available
    const isPlaying = localStorage.getItem('isPlaying');
    if (isPlaying === 'true') {
      this.setState({ isPlaying: true });
    }
  }

  setAudioSource = (source) => {
    this.setState({ audioSource: source });
    localStorage.setItem('audioSource', source);
  };

  playAudio = () => {
    this.setState({ isPlaying: true });
    localStorage.setItem('isPlaying', 'true');
  };

  pauseAudio = () => {
    this.setState({ isPlaying: false });
    localStorage.setItem('isPlaying', 'false');
  };

  render() {
    return (
      <AudioContext.Provider
        value={{
          audioSource: this.state.audioSource,
          isPlaying: this.state.isPlaying,
          setAudioSource: this.setAudioSource,
          playAudio: this.playAudio,
          pauseAudio: this.pauseAudio,
        }}
      >
        {this.props.children}
      </AudioContext.Provider>
    );
  }
}

export { AudioContextProvider, AudioContext };
