
// import React, { Component } from "react";
// import { Redirect , Route } from "react-router-dom";
// import Cookies from 'js-cookie';
// import { withRouter } from "../../withRouter";
// // import Header from "../../page/header/Header";
// // const cookies = new Cookies();

// export class PrivateRoute extends Component {
//   render() {
//     const { children } = this.props;
//     const isAuthenticated = localStorage.getItem("login") === "sucess" ? true : false;
    
//     return isAuthenticated ? (
//       <>
//         <main className="all-content">
//           <div className="container">
//             <div className="row justify-content-center">
//               <div className="col-lg-6 col-md-12 col-sm-12 main-col">
//                 {/* <Header /> */}
//                 {children}
//               </div>
//             </div>
//           </div>
//         </main>
//       </>
//     ) : (
//       <Redirect  to="/login" />
//     );
//   }
// }

// export default withRouter(PrivateRoute);


// import React, { Component } from "react";
// import { Route, Redirect } from "react-router-dom";

// export class PrivateRoute extends Component {
//   render() {
//     const { children } = this.props;
//     const isAuthenticated = localStorage.getItem("login") === "success" ? true : false;

//     return isAuthenticated ? (
//       <>
//         <main className="all-content">
//           <div className="container">
//             <div className="row justify-content-center">
//               <div className="col-lg-6 col-md-12 col-sm-12 main-col">
//                 {children}
//               </div>
//             </div>
//           </div>
//         </main>
//       </>
//     ) : (
//       <Redirect to="/login" /> // Use Redirect for navigation
//     );
//   }
// }

// export default PrivateRoute;

import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";

class PrivateRoute extends Component {
  
  render() {
    const role = localStorage.getItem('role');
    const token = localStorage.getItem('token');
    const isAuthenticated = role && token;
    return (
      isAuthenticated ? <div>{this.props.children}</div> : <Redirect to='/login' />
    )
  }
}

export default PrivateRoute;