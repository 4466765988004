import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// import { renderRoutes } from 'react-router-config';
import "./App.scss";
import { connect } from "react-redux";
import HelpModal from "./HelpModal";
import Cookies from "js-cookie";
import PrivateRoute from "./PrivateRoute";
import { AudioContextProvider } from '../src/Admin/views/Reports/AudioContext';

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

const role = localStorage.getItem('role') || 'Partner';
// Containers
const DefaultLayout = React.lazy(() => import(`./${role}/containers/DefaultLayout`));
// const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
// const Login = React.lazy(() => import("./Admin/Login/Login"));
const Login = React.lazy(() => import("./views/Pages/Login"));
const Register = React.lazy(() => import("./views/Pages/Register"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));

class App extends Component {
  componentDidMount = () => {
    const token = localStorage.getItem('token')
    if (token) {
      Cookies.set('token', token)
    }
  }
  render() {
    const isLogin = localStorage.getItem("role")
    console.log(isLogin, 'isLogin')

    return (
      <>
        <HelpModal />
        <BrowserRouter>
          <AudioContextProvider>
            <React.Suspense fallback={loading()}>
              <Switch>
                <Route
                  exact
                  path="/login"
                  name="Login Page"
                  render={(props) => <Login {...props} />}
                />
                <Route
                  exact
                  path="/register"
                  name="Register Page"
                  render={(props) => <Register {...props} />}
                />
                <Route
                  exact
                  path="/404"
                  name="Page 404"
                  render={(props) => <Page404 {...props} />}
                />
                <Route
                  exact
                  path="/500"
                  name="Page 500"
                  render={(props) => <Page500 {...props} />}
                />
                <Route
                  path="/"
                  name="Home"
                  render={(props) => (
                    <PrivateRoute>
                      <DefaultLayout {...props} />
                    </PrivateRoute>
                  )}
                />
                {/* <Route
              path="/"
              name="Home"
              render={(props) => <DefaultLayout {...props} />}
            /> */}
                {/* {isLogin ? (
                <Route
                  path="/"
                  name="Home"
                  render={(props) => (localStorage.getItem('role') ? <DefaultLayout {...props} /> : <Login {...props} />)}
                />
              ) : (
                <Route path="/"
                  name="Home"
                  render={(props) => <Login {...props} />}
                />
              )} */}
                <Route
                  exact
                  path="*"
                  name="Page 404"
                  render={(props) => <Page404 {...props} />}
                />
              </Switch>
            </React.Suspense>
          </AudioContextProvider>
        </BrowserRouter>
      </>
    );
  }
}

const mapStateToProps = (props) => {
  //console.log(props)
  return {
    props: props,
  };
};
export default connect(mapStateToProps)(App);


