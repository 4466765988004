// import React, { Component } from 'react';
// import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
// import './HelpModal.css';
// import axios from 'axios';
// import Axios from "axios";

// class HelpModal extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             isOpen: false, // To control modal visibility
//             words: {},
//             lang: "",
//         };
//     }

//     getLang = async () => {
//         const base_url = window.location.origin;
//         await axios.get(`${base_url}/lang.json`).then((res) => {
//             this.setState({ words: res.data, lang: localStorage.getItem('lang') });
//         });
//     };
//     lang = (word) => {
//         const { lang, words } = this.state;
//         return words[word] ? words[word][lang] : word;
//     };
//     // static getDerivedStateFromProps(nextProps, prevState) {
//     //     console.log(prevState,'nextProps');
//     //     if (nextProps.lang !== prevState.lang) {
//     //         return { lang: nextProps.lang };
//     //     } else return null;
//     // }

//     componentDidMount = () => {
//         this.getLang();
//     }

//     openModal = () => {
//         this.setState({ isOpen: true });
//     }

//     closeModal = () => {
//         this.setState({ isOpen: false });
//     };

//     componentDidUpdate = () => {
//         const infoButton = document.querySelector('.nav-icon.fa.fa-info-circle');
//         if (infoButton) {
//             const parentElement = infoButton.parentNode;
//             parentElement.addEventListener('click', (e) => {
//                 e.preventDefault();
//                 this.openModal();
//             });
//         }
//     }


//     render() {
//         return (
//             <div>
//                 <Modal size="lg" centered={true} className='help_modal_wrapper' isOpen={this.state.isOpen} backdrop="static" toggle={this.closeModal}>
//                     {/* <ModalHeader>Help Modal</ModalHeader> */}
//                     <ModalBody className="text-center">
//                         <h2 className='large_text'>{this.lang("Under Construction")}</h2>
//                         <p className='small_text'>{this.lang("We apologize for any inconvenience and will be back with new versions soon.")}</p>
//                         <Button className="modal_closte_button" onClick={this.closeModal}>
//                             {this.lang("Close")}
//                         </Button>
//                     </ModalBody>
//                     {/* <ModalFooter>
//                         <Button color="primary" onClick={this.closeModal}>
//                             Close
//                         </Button>
//                     </ModalFooter> */}
//                 </Modal>
//             </div>
//         );
//     }
// }

// export default HelpModal;


import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import './HelpModal.css';
import axios from 'axios';
import Axios from "axios";

class HelpModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false, // To control modal visibility
            words: {},
            lang: "",
        };
    }

    getLang = async () => {
        const base_url = window.location.origin;
        await axios.get(`${base_url}/lang.json`).then((res) => {
            this.setState({ words: res.data, lang: localStorage.getItem('lang') });
        });
    };
    lang = (word) => {
        const { lang, words } = this.state;
        return words[word] ? words[word][lang] : word;
    };
    // static getDerivedStateFromProps(nextProps, prevState) {
    //     console.log(prevState,'nextProps');
    //     if (nextProps.lang !== prevState.lang) {
    //         return { lang: nextProps.lang };
    //     } else return null;
    // }

    componentDidMount = () => {
        this.getLang();
    }

    openModal = () => {
        this.setState({ isOpen: true });
    }

    closeModal = () => {
        this.setState({ isOpen: false });
    };

    componentDidUpdate = () => {
        const infoButton = document.querySelector('.nav-icon.fa.fa-info-circle');
        if (infoButton) {
            const parentElement = infoButton.parentNode;
            parentElement.addEventListener('click', (e) => {
                e.preventDefault();
                this.openModal();
            });
        }
    }


    render() {
        return (
            <div>
                <Modal size="lg" centered={true} className='help_modal_wrapper' isOpen={this.state.isOpen} backdrop="static" toggle={this.closeModal}>
                    {/* <ModalHeader>Help Modal</ModalHeader> */}
                    <ModalBody className="text-center">
                        <h2 className='large_text'>{this.lang("Under Construction")}</h2>
                        <p className='small_text'>{this.lang("We apologize for any inconvenience and will be back with new versions soon.")}</p>
                        <Button className="modal_closte_button" onClick={this.closeModal}>
                            {this.lang("Close")}
                        </Button>
                    </ModalBody>
                    {/* <ModalFooter>
                        <Button color="primary" onClick={this.closeModal}>
                            Close
                        </Button>
                    </ModalFooter> */}
                </Modal>
            </div>
        );
    }
}

export default HelpModal;
